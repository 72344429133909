import React from 'react'
import { Link } from 'gatsby'
import { tarieven, blocks, browser, drawer } from 'images/seo/'
import config from '../config'
const { appUrl, signupRoute } = config
import { StaticImage } from "gatsby-plugin-image"

const SeoCtaRow = () => (
  <div className="content padding-l margin-xl-bottom">
    <div className="container-md">
      <div className="grid yg align-middle">
        <div className="col-6">
          <h2>Ontdek de mogelijkheden</h2>
          <p className="margin-m-bottom">
            Via Employes regel je de HR- en salarisadministratie op dezelfde
            plek. Hierdoor kun je makkelijk je personeelszaken regelen. Ontdek
            de mogelijkheden.
          </p>

          <div className="external-links margin-l-bottom">
            <div className="half">
              <div className="text">
                <Link to="/product/salarisadministratie" className="link white">
                  Salarisadministratie
                </Link>
              </div>

              <div className="text">
                <Link to="/product/verlof" className="link white">
                  Verlof
                </Link>
              </div>
            </div>

            <div className="half">
              <div className="text">
                <Link to="/product/hr" className="link white">
                  HR
                </Link>
              </div>

              <div className="text">
                <Link to="/product/declaraties" className="link white">
                  Declaraties
                </Link>
              </div>
            </div>
          </div>

          <div className="buttons">
            <Link
              to="/product/salarisadministratie"
              className="btn secondary lg no-max"
            >
              Bekijk de mogelijkheden
            </Link>
            <a
              href={appUrl + signupRoute}
              className="btn tertiary lg no-max"
              rel="nofollow"
            >
              Probeer gratis
            </a>
          </div>
        </div>

        <div className="col-6 relative">
          <StaticImage src="../images/seo/werknemers-employes@2x.webp" alt="Employes gratis proberen" placeholder="blurred" loading="lazy" />
        </div>
      </div>
    </div>
  </div>
)

export default SeoCtaRow
