import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ContactCtaRow from 'components/contact-cta-row'
import SeoCtaRow from 'components/seo-cta-row'
import Helmet from 'react-helmet'
import Layout from '../layouts'
import YouTube from 'react-youtube'
import Overlay from '../components/overlay'

import { graphql } from 'gatsby'
import marked from 'marked'

import {homeHero, logos } from 'images/home'
import { articleImage } from 'images/hulp'
import { Link } from 'gatsby'

import config from '../config'

const { appUrl, signupRoute } = config

import { StaticImage } from "gatsby-plugin-image"

class SeoPage extends Component {
  heroVideoOverlay = React.createRef()

  constructor(props) {
    super(props)
    this.state = {
      email: '',
      isHeroVideoOpened: false,
      shouldShowHeroVideo: false,
    }
  }

  get heroVideoOptions() {
    return {
      width: '100%',
      height: '100%',
      playerVars: {
        origin: location.origin,
      },
    }
  }

  handleHeroVideoReady = event => {
    this.heroVideo = event.target
    this.heroVideo.playVideo()
  }

  handleHeroVideoShow = () => {
    this.setState({ shouldShowHeroVideo: true })
  }

  handleHeroVideoFinished = () => {
    this.heroVideoOverlay.current.closeOverlay()
  }

  handleHeroVideoClose = () => {
    this.setState({
      isHeroVideoOpened: false,
    })
  }

  handleHeroVideoClosing = () => {
    this.heroVideo.stopVideo()
    this.setState({ shouldShowHeroVideo: false })
  }

  handleVideoPlayButtonClick = () => {
    this.setState({ isHeroVideoOpened: true })
    window.analytics.track('Video played', {
      videoId: 'qIF7KMa7iHM',
    })
  }

  handleVideoCloseButtonClick = () => {
    this.setState({ isHeroVideoOpened: false })
  }
  changeHandler = event => {
    this.setState({
      email: event.target.value,
    })
  }

  submitFormHandler = event => {
    event.preventDefault()
    window.location = `${appUrl}${signupRoute}?email=${this.state.email}`
    window.analytics.track('User entered email on seo-page', {
      email: this.state.email,
    })
  }

  render() {
    const { edges } = this.props.data.allContentfulSeo
    const allSEOContent = edges[0].node.content.childMarkdownRemark.html

    const { isHeroVideoOpened, shouldShowHeroVideo } = this.state

    return (
      <Layout location={this.props.location}>
        <main className="seo home">
          <Helmet>
            <title>{edges[0].node.metaTitle}</title>

            <meta name="description" content={edges[0].node.metaDescription} />
            <meta itemprop="name" content={edges[0].node.metaTitle} />
            <meta
              itemprop="description"
              content={edges[0].node.metaDescription}
            />

            <meta name="msapplication-TileColor" content="#ffffff" />
            <meta
              name="msapplication-TileImage"
              content="/static/favicon/mstile-144x144.png"
            />

            <meta property="og:url" content="" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={edges[0].node.metaTitle} />
            <meta
              property="og:description"
              content={edges[0].node.metaDescription}
            />
            <meta property="og:image" content="/static/meta-img.png" />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={edges[0].node.metaTitle} />
            <meta
              name="twitter:description"
              content={edges[0].node.metaDescription}
            />
            <meta name="twitter:image" content="/static/meta-img.png" />
          </Helmet>

          <header className="header padding-l">
            <div className="container-md relative">
              <div className="grid yg">
                <div className="col-8 header-text">
                  <h1 className="no-margin">{edges[0].node.title}</h1>
                  <h3>{edges[0].node.titleDescription}</h3>
                  <p className="streamer margin-m-bottom">
                    {edges[0].node.subtitle.subtitle}
                  </p>

                  <form className="sign-up" onSubmit={this.submitFormHandler}>
                    <input
                      type="email"
                      name="email"
                      value={this.state.email}
                      onChange={this.changeHandler}
                      placeholder="Je zakelijke e-mailadres"
                      className="large combined"
                      required
                    />
                    <button className="btn primary lg combined">
                      Probeer nu
                      <span className="hide-mobile">&nbsp;gratis</span>
                    </button>
                  </form>

                  <br />
                  <p className="small sky-dark">
                    Geheel vrijblijvend{' '}
                    <span className="hide-mobile">
                      | Geen salariskennis nodig{' '}
                    </span>{' '}
                    | Eenvoudig starten
                  </p>
                </div>

                <div
                  className="hero-illustration show-play-button"
                  onClick={this.handleVideoPlayButtonClick}
                >
                  <img src={homeHero} alt="Illustration" />
                </div>
              </div>
            </div>

            <div className="logo-grid padding-xl-top hide-mobile block">
              <div className="container-sm margin-xs-bottom">
                <div className="grid yg align-middle logos">
                  {Object.keys(logos).map((img, idx) => (
                    <div className="col-3 align-middle no-mar" key={idx}>
                      <div className={`logo ${idx === 1 ? 'petossi' : ''}`}>
                        <img src={logos[img]} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="container-sm">
                <div className="grid center text-center yg align-middle">
                  <p className="hide-mobile">{edges[0].node.tagDescription}</p>
                </div>
              </div>
            </div>
          </header>

          <section className="features padding-xl">
            <SeoCtaRow />

            <div className="container-md">
              <div className="grid yg">
                <div className="col-8">

                <div dangerouslySetInnerHTML={{ __html: allSEOContent }}></div>

                <div className="seo-link">
                    Tags:&nbsp;
                    <Link
                      className="seo-link"
                      to="/salarisadministratie-uitbesteden/"
                    >
                      Salarisadministratie uitbesteden
                    </Link>
                    ,&nbsp;
                    <Link className="seo-link" to="/salarispakket/">
                      Salarispakket
                    </Link>
                    ,&nbsp;
                    <Link className="seo-link" to="/salaris-software/">
                      Salaris-software
                    </Link>
                    ,&nbsp;
                    <Link className="seo-link" to="/personeelsadministratie/">
                      Personeelsadministratie
                    </Link>
                    ,&nbsp;<br/>
                    <Link
                      className="seo-link"
                      to="/salarisadministratie-zelf-doen/"
                    >
                      Salarisadministratie-zelf-doen
                    </Link>
                    ,&nbsp;
                    <Link className="seo-link" to="/salarisverwerking/">
                      Salarisverwerking
                    </Link>
                    ,&nbsp;<br/>
                    <Link className="seo-link" to="/loon-software/">
                      Loon-software
                    </Link>
                    ,&nbsp;
                    <Link className="seo-link" to="/loonadministratie/">
                      Loonadministratie
                    </Link>
                    ,&nbsp;<br/>
                    <Link className="seo-link" to="/loonstrook-maken/">
                      Loonstrook maken
                    </Link>
                    ,&nbsp;
                    <Link
                      className="seo-link"
                      to="/salarisadministratiekantoor/"
                    >
                      Salarisadministratiekantoor
                    </Link>
                    ,&nbsp;<br/>
                    <Link className="seo-link" to="/loonadministrateur/">
                      Loonadministrateur
                    </Link>
                    ,&nbsp;
                    <Link className="seo-link" to="/verloning/">
                      Verloning
                    </Link>
                    ,&nbsp;
                    <Link className="seo-link" to="/salarisprogramma/">
                      Salarisprogramma
                    </Link>
                    ,&nbsp;
                    <Link className="seo-link" to="/declaratiesoftware/">
                      Declaratiesoftware
                    </Link>
                    ,&nbsp;
                  </div>

                </div>

                <div className="cta-card right">
                  <StaticImage src="../images/hulp/articleImage@2x.webp" alt="Employes gratis proberen" placeholder="blurred" loading="lazy" />
                  <p className="eyebrow margin-xxs-bottom">Probeer nu gratis</p>
                  <h5 className="margin-s-bottom">
                    Ervaar het gemak en probeer Employes gratis uit.
                  </h5>
                  <a href={appUrl + signupRoute} className="btn primary">
                    Probeer nu
                  </a>
                </div>
              </div>
            </div>
          </section>
        </main>

        <Overlay
          showCloseButton
          isOpen={isHeroVideoOpened}
          ref={this.heroVideoOverlay}
          onShow={this.handleHeroVideoShow}
          onClose={this.handleHeroVideoClose}
          onClosing={this.handleHeroVideoClosing}
        >
          {shouldShowHeroVideo && (
            <YouTube
              videoId="qIF7KMa7iHM"
              opts={this.heroVideoOptions}
              containerClassName="hero-video-container"
              onReady={this.handleHeroVideoReady}
              onEnd={this.handleHeroVideoFinished}
            />
          )}
        </Overlay>
      </Layout>
    )
  }
}

export default SeoPage

export const seoQuery = graphql`
  query rightSEO($slug: String!) {
    allContentfulSeo(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          id
          metaTitle
          metaDescription
          title
          titleDescription
          tagDescription
          subtitle {
            subtitle
          }
          slug
          content {
              childMarkdownRemark{
                html
              }
          }
        }
      }
    }
  }
`
